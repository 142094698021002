import React, { JSX } from 'react';
import Select, { SingleValue } from 'react-select';

import { useTranslations } from '../../../../contexts/LocalizationContext';
import { useRestaurantQuickContacts } from '../../../../services/quick-contacts';
import { QuickContactResponse } from '../../../QuickContacts/model/quick-contact-response';

type RestaurantSelectProps = {
  quickContact: QuickContactResponse | null;
  onQuickContactChange: (option: SingleValue<QuickContactResponse>) => void;
  styles: any;
  restaurantId?: string;
};

export const QuickContactSelect = ({
  quickContact,
  restaurantId,
  onQuickContactChange,
  styles,
}: RestaurantSelectProps): JSX.Element | null => {
  const translations = useTranslations();
  const { data: quickContacts } = useRestaurantQuickContacts(restaurantId);

  return (
    <Select<QuickContactResponse>
      value={quickContact}
      getOptionLabel={(option) => option.quickContactName}
      placeholder={translations('quick_contacts_select_placeholder')}
      styles={styles}
      options={quickContacts}
      onChange={onQuickContactChange}
      isDisabled={!restaurantId}
    />
  );
};
