import React from 'react';
import { Td, Tr, useNumberInput } from '@chakra-ui/react';
import { RelevosProductCategoryWithImage } from 'api/types';

import { QuantityInput } from '../../../../../common/components/QuantityInput/QuantityInput';
import { TableItemWithImage } from '../../../../../common/components/TableItemWithImage';
import { useLocalizationContext } from '../../../../../contexts/LocalizationContext/useLocalizationContext.hook';

type Props = {
  category: RelevosProductCategoryWithImage;
  updateQuantityOfItemsInCategory: (categoryId: string, quantity: number) => void;
  categoryQuantity: number;
};

const CategoriesListItem = ({ category, updateQuantityOfItemsInCategory, categoryQuantity }: Props): JSX.Element => {
  const { language } = useLocalizationContext();
  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } = useNumberInput({
    step: 1,
    defaultValue: 0,
    min: 0,
    max: 50,
    onChange: (valueAsString, valueAsNumber) => updateQuantityOfItemsInCategory(category.id, valueAsNumber),
    value: categoryQuantity,
  });
  const inputProps = { ...getInputProps(), p: 0, m: 0, fontSize: 14 };

  return (
    <Tr>
      <Td>
        <TableItemWithImage
          text={category.translations ? category.translations[language] : ''}
          imageUrl={category.imageUrl}
        />
      </Td>
      <Td w={'180px'}>
        <QuantityInput
          inputProps={inputProps}
          decrementButtonProps={{ ...getDecrementButtonProps() }}
          incrementButtonProps={{ ...getIncrementButtonProps() }}
        />
      </Td>
    </Tr>
  );
};

export default CategoriesListItem;
