export default {
  baseStyle: {
    fontWeight: 'normal',
    _focus: {
      boxShadow: 'none',
    },
  },
  variants: {
    beigeSolid: {
      background: 'beige.50',
      _focus: {
        background: 'beige.50',
      },
      _hover: {
        background: 'beige.50',
      },
    },
    greySolid: {
      background: 'grey.50',
      _focus: {
        background: 'grey.50',
      },
      _hover: {
        background: 'grey.50',
      },
    },
    orangeSolid: {
      color: 'black',
      background: 'orange.500',
      _focus: {
        background: 'orange.500',
      },
      _hover: {
        background: 'orange.500',
        _disabled: {
          background: 'orange.500',
        },
      },
      _active: {
        background: 'orange.500',
      },
      _disabled: {
        background: 'orange.500',
      },
    },
    redSolid: {
      color: 'black',
      background: 'red.300',
      _focus: {
        background: 'red.300',
      },
      _hover: {
        background: 'red.300',
        _disabled: {
          background: 'red.300',
        },
      },
      _active: {
        background: 'red.300',
      },
      _disabled: {
        background: 'red.300',
      },
    },
    outlineNegative: {
      color: 'white',
      background: 'none',
      border: '1px',
      borderColor: 'white',
      _hover: {
        background: 'none',
      },
    },
    outline: {
      color: 'grey.200',
      background: 'none',
      border: '1px',
      borderColor: 'grey.200',
      _hover: {
        background: 'none',
      },
    },
    transparent: {
      background: 'none',
      padding: 0,
      _hover: {
        background: 'none',
      },
      sizes: {
        sm: {
          fontSize: 14,
        },
      },
    },
    menuItemButton: {
      justifyContent: 'flex-start',
      variant: 'ghost',
      font: 'Rubik',
      width: '283px',
      height: '39px',
      fontSize: 16,
      fontWeight: 'normal',
      mt: 4,
      bg: 'beige.50',
      _focus: {
        boxShadow: 'none',
        backgroundColor: 'none',
      },
      borderRadius: '8px',
      _hover: {
        backgroundColor: 'none',
      },
      _active: {
        backgroundColor: 'none',
      },
    },
    iconButton: {
      height: '14px',
      width: '14px',
    },
    incrementDecrementButton: {
      variant: 'ghost',
      p: 0,
      m: 0,
      _focus: {
        boxShadow: 'none',
        backgroundColor: 'none',
      },
      _hover: {
        backgroundColor: 'none',
      },
      _active: {
        backgroundColor: 'none',
      },
    },
  },
};
