import React from 'react';
import { Box } from '@chakra-ui/react';

import { RestaurantSelect } from '../../../../common/components/RestaurantSelect/RestaurantSelect';
import { useScannedRelevosContext } from '../../../../contexts/ScannedRelevosContext';
import { RestaurantInfo } from '../../../../contexts/SessionContext/SessionContext';
import { relevoAssignSelectStyle } from '../../styles/relevo-assign-select-style';
import { SelectAssignmentMode } from '../SelectAssignmentMode/SelectAssignmentMode';
import { QuickContactReturnItemsList } from './components/QuickContactReturnItemsList';

type QuickContactsReturnItemsListProps = {
  restaurants: RestaurantInfo[];
};

export const QuickContactsReturnItems = ({ restaurants }: QuickContactsReturnItemsListProps) => {
  const { restaurantId, setRestaurantId } = useScannedRelevosContext();

  return (
    <Box>
      <Box bg={['beige.50', 'beige.50', 'beige.50', 'none', 'none']} width={'100%'} px={[4, 4, 2, 2, 2]} py={6}>
        <Box display={['block', 'block', 'block', 'none', 'none']} width={['100%', '100%', '50%', '50%']}>
          <SelectAssignmentMode />
        </Box>
        <Box
          width="100%"
          alignItems={['center', 'center', 'none', 'none']}
          justifyContent={['center', 'center', 'left', 'left']}
          display={'flex'}
          pt={[4, 4, 4, 0]}
        >
          <Box width={'270px'}>
            <RestaurantSelect
              userRestaurants={restaurants}
              restaurantId={restaurantId}
              setRestaurantId={setRestaurantId}
              styles={relevoAssignSelectStyle}
            />
          </Box>
        </Box>
      </Box>
      {restaurantId ? <QuickContactReturnItemsList restaurantId={restaurantId} /> : null}
    </Box>
  );
};
