import React from 'react';
import { Table, Tbody, Th, Thead, Tr } from '@chakra-ui/react';
import { CategoryQuantity, RelevosProductCategoryWithImage } from 'api/types';

import { RelevoTableContainer } from '../../../../../common/components/Table/TableHelper';
import { useTranslations } from '../../../../../contexts/LocalizationContext';
import CategoriesListItem from './CategoriesListItem';

type Props = {
  updateQuantityOfItemsInCategory: (categoryId: string, quantity: number) => void;
  categoriesToCreate: CategoryQuantity;
  categories?: RelevosProductCategoryWithImage[];
};

const CategoriesList = ({ updateQuantityOfItemsInCategory, categoriesToCreate, categories }: Props): JSX.Element => {
  const translations = useTranslations();

  return (
    <RelevoTableContainer>
      <Table sx={{ tableLayout: 'auto' }}>
        <Thead>
          <Tr>
            <Th>{translations('category_list_category')}</Th>
            <Th>{translations('category_list_quantity')}</Th>
          </Tr>
        </Thead>
        <Tbody backgroundColor={'white'}>
          {categories
            ? categories.map((category) => (
                <CategoriesListItem
                  key={category.id}
                  category={category}
                  updateQuantityOfItemsInCategory={updateQuantityOfItemsInCategory}
                  categoryQuantity={categoriesToCreate[category.id] || 0}
                />
              ))
            : null}
        </Tbody>
      </Table>
    </RelevoTableContainer>
  );
};

export default CategoriesList;
