import React, { JSX } from 'react';
import { Box, Flex, useTheme, VStack } from '@chakra-ui/react';
import { ScannedRelevosContextProvider } from 'contexts/ScannedRelevosContext';

import { MainPageContent } from '../../common/components/MainPageContent';
import MainPageHeading from '../../common/components/MainPageHeading/MainPageHeading';
import { NoLiveLocationsHeader } from '../../common/components/NoActiveLocationsHeader/NoLiveLocationsHeader';
import { useTranslations } from '../../contexts/LocalizationContext';
import { useRestaurantsData } from '../../services/restaurants';
import RelevosSteps from './common/components/RelevosSteps/RelevosSteps';
import Upgrade from './components/Upgrade';
import UserSelect from './components/UserSelect';
import { UserSelectMode } from './components/UserSelect/types';
import { ScannerMode } from './model/enums/scanner-mode.enum';

const CardUpgrade = (): JSX.Element => {
  const translations = useTranslations();
  const { colors } = useTheme();
  const { restaurantIds } = useRestaurantsData();

  return (
    <MainPageContent>
      {restaurantIds.length ? (
        <Box mb={2}>
          <MainPageHeading>{translations('relevos_upgrade_heading')}</MainPageHeading>
          <ScannedRelevosContextProvider mode={ScannerMode.CARD_UPGRADE}>
            <VStack spacing="20px">
              <Flex bgColor={colors.beige[50]} marginTop="18px" height="100%" width="100%" pl={6} py={4} wrap="wrap">
                <Box height="100%" py={4} width={['100%', '100%', '100%', '100%', '50%']}>
                  <RelevosSteps
                    firstStep={translations('relevos_step_1')}
                    firstStepDescription={translations('relevos_step_1_description')}
                    secondStep={translations('relevos_step_2')}
                    secondStepDescription={translations('relevos_upgrade_step_2_description')}
                    isUpgrade
                  />
                </Box>
                <UserSelect mode={UserSelectMode.UPGRADE} />
              </Flex>
              <Box width="stretch">
                <Upgrade />
              </Box>
            </VStack>
          </ScannedRelevosContextProvider>
        </Box>
      ) : (
        <NoLiveLocationsHeader />
      )}
    </MainPageContent>
  );
};

export default CardUpgrade;
