import React from 'react';
import { useNumberInput } from '@chakra-ui/react';

import { QuantityInput } from '../../../../../../common/components/QuantityInput/QuantityInput';
import { LanguagesConfig } from '../../../../../../contexts/LocalizationContext/types';

type Props = {
  category: { id: string; translations: LanguagesConfig };
  onUpdateItemsQuantity: (categoryId: string, quantity: number, userId: string) => void;
  categoryQuantity: number;
  userId: string;
  maxValue: number;
};

export const CategoryQuantityUnknownItem = ({
  category,
  onUpdateItemsQuantity,
  categoryQuantity,
  userId,
  maxValue,
}: Props) => {
  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } = useNumberInput({
    step: 1,
    defaultValue: 0,
    min: 0,
    max: maxValue,
    onChange: (valueAsString, valueAsNumber) => onUpdateItemsQuantity(category.id, valueAsNumber, userId),
    value: categoryQuantity,
  });
  const inputProps = { ...getInputProps(), p: 0, m: 0, fontSize: 14 };
  return (
    <QuantityInput
      inputProps={inputProps}
      decrementButtonProps={{ ...getDecrementButtonProps() }}
      incrementButtonProps={{ ...getIncrementButtonProps() }}
    />
  );
};
