import React from 'react';
import { Box, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';

import { FullPageLoader } from '../../../../../../common/components/Loader/FullPageLoader';
import { RelevoTableContainer } from '../../../../../../common/components/Table/TableHelper';
import { TableItemWithImage } from '../../../../../../common/components/TableItemWithImage';
import { useTranslations } from '../../../../../../contexts/LocalizationContext';
import { useLocalizationContext } from '../../../../../../contexts/LocalizationContext/useLocalizationContext.hook';
import { ReturnUnknownRelevosInformationRequest } from '../../../../model/return-unknown-relevos-request';
import { UnknownRelevosListResponse } from '../../../../model/unknown-relevos-list-response';
import { CategoryQuantityUnknownItem } from '../CategoryQuantityUnknownItem/CategoryQuantityUnknownItem';

type QuickContactsReturnItemsTableProps = {
  itemsToReturn: ReturnUnknownRelevosInformationRequest[];
  onUpdateItemsQuantity: (categoryId: string, quantity: number, userId: string) => void;
  isLoadingItems: boolean;
  items?: UnknownRelevosListResponse[];
};

export const QuickContactsReturnItemsTable = ({
  items,
  isLoadingItems,
  itemsToReturn,
  onUpdateItemsQuantity,
}: QuickContactsReturnItemsTableProps) => {
  const translations = useTranslations();
  const { language } = useLocalizationContext();

  return (
    <>
      <Text py={4} textAlign={'left'} fontWeight={'bold'}>
        {translations('quick_contacts_return_list_table_header')}
      </Text>
      <FullPageLoader show={isLoadingItems}>
        <RelevoTableContainer>
          <Table variant={'quickContactsReturnItems'} overflowX="auto">
            <Thead>
              <Tr>
                <Th>{translations('quick_contacts_return_list_category_header')}</Th>
                <Th>{translations('quick_contacts_return_list_user_header')}</Th>
                <Th>{translations('quick_contacts_return_list_quantity_header')}</Th>
                <Th>{translations('quick_contacts_return_list_borrowed_header')}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {items?.length ? (
                items.map((item) => (
                  <Tr>
                    <Td>
                      <TableItemWithImage
                        text={item.category.translations[language]}
                        imageUrl={item.category.imageUrl}
                      />
                    </Td>
                    <Td>{item.user.firstName}</Td>
                    <Td>
                      <Box w={'150px'}>
                        <CategoryQuantityUnknownItem
                          category={item.category}
                          onUpdateItemsQuantity={onUpdateItemsQuantity}
                          categoryQuantity={
                            itemsToReturn.find(
                              (itemToReturn) =>
                                itemToReturn.userId === item.user.id && itemToReturn.categoryId === item.category.id,
                            )?.quantity || 0
                          }
                          maxValue={item.borrowingsCount || 0}
                          userId={item.user.id}
                        />
                      </Box>
                    </Td>
                    <Td>{item.borrowingsCount}</Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan={5} p={5}>
                    <Text textAlign="center">{translations('no_data')}</Text>
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </RelevoTableContainer>
      </FullPageLoader>
    </>
  );
};
