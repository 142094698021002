import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Box, Input, List, ListItem, Text, useTheme } from '@chakra-ui/react';
import { getApplicationUsers, SearchUserType } from 'api/applicationUser';
import { ApplicationUser } from 'api/types';
import { useScannedRelevosContext } from 'contexts/ScannedRelevosContext';
import { useCombobox } from 'downshift';

import { useDebounce } from '../../../../common/hooks/useDebounce';
import { DEBOUNCE_SEARCH_TIME } from '../../../../config/configConsts';
import { useTranslations } from '../../../../contexts/LocalizationContext';
import { UserSelectMode } from './types';

type Props = {
  mode: UserSelectMode;
  placeholder?: string;
  maxLength?: number;
};

const Autocomplete = ({ placeholder, maxLength, mode }: Props): JSX.Element => {
  const [usersList, setUsersList] = useState<ApplicationUser[]>([]);
  const { setApplicationUser, restaurantId } = useScannedRelevosContext();
  const translations = useTranslations();
  const { colors } = useTheme();
  const [searchText, setSearchText] = React.useState('');
  const debouncedSearch = useDebounce(searchText, DEBOUNCE_SEARCH_TIME);

  const updateUsersList = useCallback(
    (search: string) => {
      const searchUserType = mode === UserSelectMode.UPGRADE ? SearchUserType.Card : undefined;
      getApplicationUsers(search, searchUserType)
        .then((applicationUsers) => {
          if (!applicationUsers.length) {
            setUsersList([]);
          } else {
            setUsersList(applicationUsers);
          }
        })
        .catch(() => {
          setUsersList([]);
          toast.error(<b>{translations('something_went_wrong')}</b>);
        });
    },
    // eslint-disable-next-line
    [mode], // FYI: translations in deps causes endless rerendering
  );

  useEffect(() => {
    if (debouncedSearch.length) {
      updateUsersList(debouncedSearch);
    }
  }, [debouncedSearch, updateUsersList]);

  const { getMenuProps, getInputProps, getItemProps } = useCombobox({
    items: usersList,
    itemToString: (item) => item?.userId || '',
    onInputValueChange: ({ inputValue, type }) => {
      if (!inputValue) {
        setUsersList([]);
        setApplicationUser(null);
      } else if (inputValue.length >= 3 && type === useCombobox.stateChangeTypes.InputChange) {
        setApplicationUser(null);
        setSearchText(inputValue);
        // updateUsersList(inputValue);
      } else {
        setSearchText(inputValue);
        setUsersList([]);
      }
    },
    defaultHighlightedIndex: 0,
    onSelectedItemChange: ({ selectedItem: newItem }) => {
      console.log(newItem);
      setApplicationUser(newItem as ApplicationUser);
    },
  });

  return (
    <Box mx="auto" textAlign="center" position="relative">
      <Input
        px={2}
        _focus={{ borderColor: colors.orange[500] }}
        _placeholder={{ color: colors.grey[200] }}
        variant="flushed"
        color={colors.black}
        borderBottomWidth="1px"
        borderColor={colors.grey[200]}
        mb={4}
        isTruncated
        data-testid="autocomplete-input"
        placeholder={placeholder}
        maxLength={maxLength}
        {...getInputProps()}
        isDisabled={!restaurantId}
      />
      <List
        overflowY="auto"
        zIndex={3}
        maxHeight="200px"
        color={colors.black}
        width="100%"
        position="absolute"
        bg={usersList.length ? colors.orange[500] : 'none'}
        borderRadius="lg"
        mb={4}
        py={2}
        {...getMenuProps()}
      >
        {usersList.map((item, index) => (
          <Box
            fontWeight="normal"
            color={colors.black}
            cursor="pointer"
            {...getItemProps({ item, index, key: item.userId })}
            mx={2}
            my={1}
          >
            <ListItem>
              <Text>{item.userId}</Text>
            </ListItem>
          </Box>
        ))}
      </List>
    </Box>
  );
};

export default Autocomplete;
