import React from 'react';
import { Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';

import { useTranslations } from '../../../../../../contexts/LocalizationContext';
import { useLocalizationContext } from '../../../../../../contexts/LocalizationContext/useLocalizationContext.hook';
import { ReturnUnknownRelevosInformationRequest } from '../../../../model/return-unknown-relevos-request';
import { UnknownRelevosListResponse } from '../../../../model/unknown-relevos-list-response';

type ReturnUnknownItemsConfirmModalBodyProps = {
  itemsToReturn: ReturnUnknownRelevosInformationRequest[];
  unknownItems?: UnknownRelevosListResponse[];
};

export const ReturnUnknownItemsConfirmModalBody = ({
  itemsToReturn,
  unknownItems,
}: ReturnUnknownItemsConfirmModalBodyProps) => {
  const translations = useTranslations();
  const { language } = useLocalizationContext();

  return (
    <>
      <Thead>
        <Tr>
          <Th>{translations('category_list_category')}</Th>
          <Th>{translations('category_list_user')}</Th>
          <Th>{translations('category_list_quantity')}</Th>
        </Tr>
      </Thead>
      <Tbody>
        {itemsToReturn
          .filter((item) => item.quantity > 0)
          .map((item, index) => (
            <Tr key={index}>
              <Td>
                {
                  unknownItems?.find((unknownItem) => unknownItem.category.id === item.categoryId)?.category
                    .translations[language]
                }
              </Td>
              <Td>{unknownItems?.find((unknownItem) => unknownItem.user.id === item.userId)?.user.firstName}</Td>
              <Td>{item.quantity}</Td>
            </Tr>
          ))}
      </Tbody>
    </>
  );
};
