import React, { useMemo } from 'react';
import { Box, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';

import { RelevosProductCategoryWithImage } from '../../../../../../api/types';
import { ItemsSummaryAccordion } from '../../../../../../common/components/ItemsSummaryModal/ItemsSummaryAccordion';
import { ItemsSummaryStatusText } from '../../../../../../common/components/ItemsSummaryModal/ItemsSummaryStatusText';
import { RelevoSimpleTableContainer } from '../../../../../../common/components/Table/TableHelper';
import { useTranslations } from '../../../../../../contexts/LocalizationContext';
import { useLocalizationContext } from '../../../../../../contexts/LocalizationContext/useLocalizationContext.hook';
import {
  ReturnUnknownRelevosInformationFailureResponse,
  ReturnUnknownRelevosInformationSuccessResponse,
} from '../../../../model/return-unknown-relevos-response';
import { ApplicationUserResponse } from '../../../../model/unknown-relevos-list-response';

type AddNewContactsResultModalBodyProps = {
  failureItems: ReturnUnknownRelevosInformationFailureResponse[];
  successItems: ReturnUnknownRelevosInformationSuccessResponse[];
  categories?: RelevosProductCategoryWithImage[];
  users?: ApplicationUserResponse[];
};

export const ReturnUnknownItemsResultModalBody = ({
  successItems,
  failureItems,
  categories,
  users,
}: AddNewContactsResultModalBodyProps) => {
  const translations = useTranslations();
  const { language } = useLocalizationContext();
  const successItemsNumber = useMemo(
    () =>
      successItems.map((item) => item.quantity).reduce((accumulator, currentValue) => accumulator + currentValue, 0),
    [successItems],
  );
  const failureItemsNumber = useMemo(
    () =>
      failureItems.map((item) => item.quantity).reduce((accumulator, currentValue) => accumulator + currentValue, 0),
    [failureItems],
  );
  const summaryText = translations('unknown_items_return_result_modal_summary_text', {
    '{{successItems}}': successItemsNumber.toString(),
    '{{allItems}}': (successItemsNumber + failureItemsNumber).toString(),
  });

  return (
    <>
      <ItemsSummaryStatusText
        summaryText={summaryText}
        successItems={successItems || []}
        failureItems={failureItems || []}
      />
      {failureItems?.length ? (
        <Box px={8} pt={4}>
          <ItemsSummaryAccordion
            buttonText={translations('unknown_items_return_result_modal_failure_text')}
            isOpen={true}
          >
            <RelevoSimpleTableContainer>
              <Table variant="relevoSummary" overflowX="auto">
                <Thead>
                  <Tr>
                    <Th>{translations('unknown_items_return_result_modal_category')}</Th>
                    <Th>{translations('unknown_items_return_result_modal_user')}</Th>
                    <Th>{translations('unknown_items_return_result_modal_quantity')}</Th>
                    <Th>{translations('error')}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {failureItems
                    ? failureItems.map((item, index) => (
                        <Tr key={`${item.userId}${index}`}>
                          <Td>
                            {categories?.find((category) => category.id === item.categoryId)?.translations[language]}
                          </Td>
                          <Td>{users?.find((user) => user.id === item.userId)?.firstName}</Td>
                          <Td>{item.quantity}</Td>
                          <Td>{item.message || item.error}</Td>
                        </Tr>
                      ))
                    : null}
                </Tbody>
              </Table>
            </RelevoSimpleTableContainer>
          </ItemsSummaryAccordion>
        </Box>
      ) : null}
    </>
  );
};
