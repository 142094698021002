import React, { useRef } from 'react';
import { Table } from '@chakra-ui/react';

import { RelevosProductCategoryWithImage } from '../../../../../api/types';
import { ConfirmDialogBody } from '../../../../../common/components/ConfirmationDialog/ConfirmDialogBody';
import Dialog from '../../../../../common/components/ConfirmationDialog/Dialog';
import { RelevoSimpleTableContainer } from '../../../../../common/components/Table/TableHelper';
import { useTranslations } from '../../../../../contexts/LocalizationContext';

type UnknownItemsConfirmModalProps = {
  isOpen: boolean;
  handleClose: () => void;
  onConfirm: () => void;
  confirmText: string;
  confirmButtonText: string;
  children: React.ReactNode;
  categories?: RelevosProductCategoryWithImage[];
};

export const UnknownItemsConfirmModal = ({
  isOpen,
  handleClose,
  onConfirm,
  confirmButtonText,
  confirmText,
  children,
}: UnknownItemsConfirmModalProps) => {
  const translations = useTranslations();
  const cancelRef = useRef(null);

  return (
    <Dialog
      leastDestructiveRef={cancelRef}
      isOpen={isOpen}
      onClose={handleClose}
      onConfirm={onConfirm}
      confirmText={confirmButtonText}
      cancelText={translations('cancel')}
      size="3xl"
    >
      <ConfirmDialogBody confirmText={confirmText}>
        <RelevoSimpleTableContainer>
          <Table variant="relevoSummary" overflowX="auto">
            {children}
          </Table>
        </RelevoSimpleTableContainer>
      </ConfirmDialogBody>
    </Dialog>
  );
};
