import React, { JSX, useRef } from 'react';
import { AlertDialogFooter, Button } from '@chakra-ui/react';

import Dialog from '../../../../../../common/components/ConfirmationDialog/Dialog';
import { useTranslations } from '../../../../../../contexts/LocalizationContext';
import { ReturnUnknownRelevosResponse } from '../../../../model/return-unknown-relevos-response';
import { UnknownRelevosListResponse } from '../../../../model/unknown-relevos-list-response';
import { ReturnUnknownItemsResultModalBody } from '../ReturnUnknownItemsResultModalBody/ReturnUnknownItemsResultModalBody';

type ReturnUnknownItemsModalProps = {
  isVisible: boolean;
  onCancel: () => void;
  result: ReturnUnknownRelevosResponse;
  unknownItems?: UnknownRelevosListResponse[];
};
export const ReturnUnknownItemsModal = ({
  isVisible,
  onCancel,
  result,
  unknownItems,
}: ReturnUnknownItemsModalProps): JSX.Element | null => {
  const translations = useTranslations();
  const cancelRef = useRef(null);

  return (
    <Dialog
      dialogHeader={translations('unknown_items_return_result_modal_header')}
      size="3xl"
      isOpen={isVisible}
      onClose={onCancel}
      leastDestructiveRef={cancelRef}
      dialogFooter={
        <AlertDialogFooter justifyContent="flex-start">
          <Button variant="orangeSolid" onClick={onCancel}>
            {translations('ok')}
          </Button>
        </AlertDialogFooter>
      }
    >
      <ReturnUnknownItemsResultModalBody
        failureItems={result.notAssignedItems}
        successItems={result.assignedItems}
        categories={unknownItems?.map((item) => item.category)}
        users={unknownItems?.map((item) => item.user)}
      />
    </Dialog>
  );
};
