import React, { JSX, useRef, useState } from 'react';
import { WarningTwoIcon } from '@chakra-ui/icons';
import { Box, Button, Center, Flex, HStack, Image, Text, VStack } from '@chakra-ui/react';

import Dialog from '../../../../../common/components/ConfirmationDialog/Dialog';
import Step from '../../../../../common/components/Stepper/Step';
import Stepper from '../../../../../common/components/Stepper/Stepper';
import { useTranslations } from '../../../../../contexts/LocalizationContext';
import { useScannedRelevosContext } from '../../../../../contexts/ScannedRelevosContext';
import { useRestaurantsData } from '../../../../../services/restaurants';
import colors from '../../../../../styles/colors';
import { QuickContactType } from '../../../../QuickContacts/model/enums/quick-contact-type.enum';

const acceptButtonDefaultProps = {
  _focus: { boxShadow: 'none', backgroundColor: colors.beige[50] },
  _hover: { backgroundColor: colors.beige[50] },
  _active: { backgroundColor: colors.beige[50] },
};

type RelevosStepsDefaultProps = {
  firstStep: string;
  firstStepDescription: string;
  secondStep: string | JSX.Element;
  secondStepDescription?: string;
  isUpgrade: boolean;
};

const RelevosSteps = ({ ...props }: RelevosStepsDefaultProps): JSX.Element | null => {
  const {
    applicationUser,
    quickContact,
    setApplicationUser,
    setQuickContact,
    restaurantId,
    setRestaurantId,
    scannedRelevos,
    setScannedRelevos,
  } = useScannedRelevosContext();
  const { restaurantIds, restaurants } = useRestaurantsData();
  const translations = useTranslations();
  const [isOpen, setIsOpen] = useState(false);
  const cancelRef = useRef(null);

  const handleClose = () => setIsOpen(false);
  const handleConfirm = () => {
    setApplicationUser(null);
    setQuickContact(null);
    if (scannedRelevos.length) {
      setScannedRelevos([]);
    }
    setIsOpen(false);
  };

  const handleClearRestaurant = () => {
    setRestaurantId(null);
    setQuickContact(null);
  };

  const getSvgImgFilter = (isActive: boolean) =>
    isActive
      ? { filter: 'invert(58%) sepia(98%) saturate(693%) hue-rotate(2deg) brightness(126%) contrast(99%)' }
      : { filter: 'invert(46%) sepia(0%) saturate(0%) hue-rotate(195deg) brightness(135%) contrast(91%)' };

  const getIconPath = (iconName: string) => require(`../../../../../assets/icons/${iconName}`);

  return (
    <HStack alignItems="stretch">
      <Stepper activeIndex={!applicationUser && !quickContact ? 0 : 1}>
        <Step index={0}>
          <HStack>
            <VStack alignItems="start" spacing="0px">
              <HStack alignItems="start" spacing="0px">
                <Center w={'70px'} m={'auto'}>
                  <Image
                    boxSize={'40px'}
                    src={getIconPath('client.svg')}
                    style={getSvgImgFilter(!applicationUser && !quickContact)}
                    alt="Client icon"
                  />
                </Center>
                <VStack alignItems="start" textAlign="left" spacing="0px" ml={2}>
                  <Text
                    color={!applicationUser && !quickContact ? colors.grey[900] : colors.grey[100]}
                    fontWeight="bold"
                  >
                    {props.firstStep}
                  </Text>
                  <Text color={!applicationUser && !quickContact ? colors.grey[900] : colors.grey[100]}>
                    {props.firstStepDescription}
                  </Text>
                </VStack>
              </HStack>

              <HStack style={!applicationUser && !quickContact ? { display: 'none' } : {}}>
                <Image src={getIconPath('done.svg')} alt={'Done icon'} mr={2} />
                <Text
                  fontSize="18px"
                  textAlign="left"
                  color={applicationUser || quickContact ? colors.grey[900] : colors.beige[50]}
                >
                  {translations('user')} <b>{applicationUser?.userId || quickContact?.quickContactName}</b>
                </Text>
                <Button
                  {...acceptButtonDefaultProps}
                  backgroundColor={colors.beige[50]}
                  color={colors.orange[500]}
                  fontSize="16px"
                  fontWeight="normal"
                  onClick={() => setIsOpen(true)}
                >
                  {translations('scan_clear_relevo')}
                </Button>
              </HStack>
              {applicationUser && !applicationUser.paymentInformationValid ? (
                <Flex backgroundColor={colors.orange[500]} py={1} px={2} borderRadius={6}>
                  <Center p={1}>
                    <WarningTwoIcon />
                  </Center>
                  <Text textAlign="left" fontSize="16px">
                    <b>{translations('no_valid_payment_information')}</b>
                  </Text>
                </Flex>
              ) : null}

              <HStack style={restaurantIds.length === 1 || !restaurantId ? { display: 'none' } : {}}>
                <Image src={getIconPath('done.svg')} alt={'Done icon'} mr={2} />
                <Text color={restaurantId ? colors.grey[900] : colors.beige[50]} textAlign="left" fontSize="18px">
                  {translations('restaurant')} <b>{restaurants.find((r) => r.id === restaurantId)?.restaurantName}</b>
                </Text>
                <Button
                  {...acceptButtonDefaultProps}
                  backgroundColor={colors.beige[50]}
                  color={colors.orange[500]}
                  fontSize="16px"
                  fontWeight="normal"
                  onClick={handleClearRestaurant}
                >
                  {translations('scan_clear_relevo')}
                </Button>
              </HStack>
              <Dialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={handleClose}
                dialogHeader={<Text>{translations('scan_clear_client_id_confirm')}</Text>}
                onConfirm={handleConfirm}
                cancelText={translations('scan_remove_cancel')}
                confirmText={translations('scan_remove_confirmed')}
              >
                <Text>{translations('scan_clear_client_id_confirm_description')}</Text>
              </Dialog>
            </VStack>
          </HStack>
        </Step>
        <Step index={1}>
          <HStack>
            <VStack spacing="0px">
              <HStack alignItems="start" spacing="0px">
                {props.isUpgrade && (
                  <div
                    style={{
                      display: 'flex',
                      width: '40px',
                      justifyContent: 'center',
                      marginRight: '15px',
                      marginTop: '4px',
                    }}
                  >
                    <img
                      src={getIconPath('upgrade.svg')}
                      alt="Upgrade icon"
                      style={getSvgImgFilter(!!applicationUser)}
                    />
                  </div>
                )}
                {!props.isUpgrade && (
                  <Center w={'70px'} m={'auto'}>
                    <Flex justifyContent={'center'}>
                      <Image
                        mr={2}
                        w="30px"
                        mt={3}
                        src={getIconPath('bowl.svg')}
                        alt="Bowl icon"
                        style={getSvgImgFilter(!!applicationUser || !!quickContact)}
                      />
                      <Image
                        w="20px"
                        src={getIconPath('cup.svg')}
                        alt="Cup icon"
                        style={getSvgImgFilter(!!applicationUser || !!quickContact)}
                      />
                    </Flex>
                  </Center>
                )}

                <VStack alignItems="start" textAlign="left" display={'flex'} spacing="0px" my={'auto'} ml={2}>
                  <Text color={applicationUser || quickContact ? colors.grey[900] : colors.grey[100]} fontWeight="bold">
                    {props.secondStep}
                  </Text>
                  {quickContact?.type === QuickContactType.RESTAURANT ? (
                    <Text color={applicationUser || quickContact ? colors.grey[900] : colors.grey[100]}>
                      {translations('item_select_mode_scan_items')}
                    </Text>
                  ) : null}
                  {props.secondStepDescription ? (
                    <Box color={applicationUser || quickContact ? colors.grey[900] : colors.grey[100]}>
                      {props.secondStepDescription}
                    </Box>
                  ) : null}
                </VStack>
              </HStack>
            </VStack>
          </HStack>
        </Step>
      </Stepper>
    </HStack>
  );
};
export default RelevosSteps;
